import React, { useState } from "react";
import './Faq.css';
import quest from '../img/quest.png'


const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <div className="faq-question" onClick={toggleOpen}>
        {question}
        <span className={`faq-arrow ${isOpen ? 'open' : ''}`}>&#x25BC;</span>
      </div>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        <p>{answer}</p>
      </div>
    </div>
  );
};

const Faq = () => {
  return (
    <div className="faq-container">
        <div className="title-with-icon2">
        <img src={quest} alt="FAQ Icon" className="iconfaq"/>
        <div class="vr2"></div>
        <h1 className="titufaq">FAQ</h1>
       </div> 
       <FaqItem 
        question="O que é a Revival Conference?" 
        answer={
          <>
            <p>REVIVAL CONFERENCE é a conferência cristã, interdenominacional, que busca gerar avivamento para a cidade de Belém. Cremos que nossa nação está num tempo de ativação do Reino dos céus. E nós, como igreja de Cristo, estamos nos posicionando para viver e ativar esse movimento no norte do Brasil.</p>
            <p>Temos preletores nacionais e internacionais. Dentro da conferência teremos sala profética, workshops, diversos stands e muita coisa incrível para você viver uma experiência completa e ter sua vida impactada nesses dias.</p>
            <p>Em todas as edições recebemos testemunhos de pessoas que foram avivadas, transformadas e curadas durante a Revival Conference. Cremos que prodígios, milagres e maravilhas vão seguir acontecendo sobre nós e sobre Belém e que muitas pessoas ainda irão receber palavras de destinos, para serem usados nas esferas da sociedade, nos 4 cantos do Brasil e em todo o mundo.</p>
          </>
        }
      />
      <FaqItem 
        question="Quando e onde acontecerá a Revival Conference 2024?" 
        answer="A RVL 24 acontecerá nos dias 04 e 05 de outubro no Hangar - Centro de convenções da Amazônia - Av. Dr. Freitas, s/n - Marco, Belém - PA" 
      />
      <FaqItem 
        question="Para quem é a Revival Conference?" 
        answer={
          <>
            <p>Nós cremos que o norte do Brasil está passando por um avivamento e nós, como igreja, estamos nos posicionando para ativar cristãos saudáveis a viverem um avivamento pessoal e assim alcançarem as esferas da sociedade e trazer o reino dos céus para Belém. São dois dias onde você viverá uma experiência única de avivamento dentro de atmosferas cheias da presença de Deus e carregadas de intensos momentos de adoração, louvor e revelação da Palavra.</p>
            
            <p>E também para aqueles que:</p>
      
            <ul>
              <li>Querem ser avivados de forma pessoal;</li>
              <li>Creem que podemos mudar a realidade social da cidade de Belém;</li>
              <li>Buscam viver um estilo de vida naturalmente sobrenatural;</li>
              <li>Querem fazer história com Deus;</li>
              <li>Querem levar o evangelho todas as nações e esferas da sociedade;</li>
              <li>Buscam investir em coisas eternas.</li>
            </ul>
          </>
        }
      />
      <FaqItem 
        question="Os ingressos dão direito a todas as sessões da Revival Conference 24?" 
        answer="Sim. Com seu ingresso você acessa aos dois dias de RVL com as 6 sessões." 
      />
      <FaqItem 
        question="Os ingressos dão direito à hospedagem e alimentação?" 
        answer="Não. A hospedagem e alimentação não estão inclusos no valor do ingresso. Esses são de responsabilidade de cada participante. O ingresso dá somente acesso ao evento. Teremos espaço para alimentação dentro do Hangar." 
      />
      <FaqItem 
        question="Quais documentos eu apresento na hora do Check-in?" 
        answer="Você precisa levar somente o ingresso impresso ou apresentar o QR Code  em seu smartphone." 
      />
    <FaqItem 
        question="Como garanto meu ingresso?" 
        answer="Os ingressos da RVL estão sendo vendidos unicamente pela plataforma TICKETO." 
    />
    <FaqItem 
        question="Quais as formas de pagamento?" 
        answer="Os ingressos podem ser adquiridos através do site Revival Conference (
                https://www.revivalconference.com.br/), com opção de pagamento através de cartão crédito, com opção de parcelamento com e sem juros (a quantidade de parcelas influencia o valor do juros), através de PIX ou de Boleto Bancário." 
      />
      <FaqItem 
        question="Posso alterar as informações do meu ingresso?" 
        answer="Sim, caso você preciso alterar, é só acessar a plataforma do https://www.tiketo.com.br" 
      />
      <FaqItem 
        question="Posso comprar mais de um ingresso com o mesmo CPF?" 
        answer="Sim, é possível garantir mais de um ingresso com seu acesso. Não fazemos pedido do CPF." 
      />
      <FaqItem 
        question="Meu ingresso é transferível?" 
        answer="Sim, você pode fazer esse processo pela plataforma do tiketo. https://www.tiketo.com.br" 
      />
      <FaqItem 
        question="Sobre reembolso?" 
        answer="É possível realizar pela plataforma do tiketo." 
      />
      <FaqItem 
        question="Qual a classificação etária?" 
        answer="Nós não recomendamos a participação de crianças de 0-12 anos, pois não temos estrutura disponível para acomodá-las, e nem uma programação específica para o público infantil. Lembrando que, caso haja a participação, deverão estar acompanhadas de seus pais e crianças a partir de 12 anos pagam ingresso integral." 
      />
      <FaqItem 
        question="Sobre meia entrada" 
        answer={
          <>
            <p>A ASSOCIAÇÃO REVIVAL CONFERENCE ESCLARECE QUE NÃO SE ENQUADRA NA LEI 12933/2013, MAIS CONHECIDA COMO A LEI FEDERAL DA MEIA ENTRADA, PELO FATO DE PROMOVER CULTOS DE MINISTRAÇÃO RELIGIOSA E CONFERÊNCIAS DE CUNHO RELIGIOSO. ESCLARECE AINDA QUE O ARTIGO 1″ DA REFERIDA LEI PREVÊ A MEIA ENTRADA A ESTUDANTES SOMENTE EM CINEMAS, TEATROS, ESPETÁCULOS MUSICAIS, ESPORTIVOS, DE LAZER E ENTRETENIMENTO EM TODO O TERRITÓRIO NACIONAL, CONFORME REDAÇÃO ABAIXO TRANSCRITA:</p>
            <p>ART. 1O É ASSEGURADO AOS ESTUDANTES O ACESSO A SALAS DE CINEMA, CINECLUBES, TEATROS, ESPETÁCULOS MUSICAIS E CIRCENSES E EVENTOS EDUCATIVOS, ESPORTIVOS, DE LAZER E DE ENTRETENIMENTO, EM TODO O TERRITÓRIO NACIONAL, PROMOVIDOS POR QUAISQUER ENTIDADES E REALIZADOS EM ESTABELECIMENTOS PÚBLICOS OU PARTICULARES, MEDIANTE PAGAMENTO DA METADE DO PREÇO DO INGRESSO EFETIVAMENTE COBRADO DO PÚBLICO EM GERAL.</p>
          </>
        } 
      />
      <FaqItem 
        question="I'm not from Brazil. How can I participate?" 
        answer="International buyers can get information about Dunamis Conference tickets through our e-mail: rvlconference@gmail.com" 
      />
      <FaqItem 
        question="Estacionamento" 
        answer="O evento será realizado no Hangar - Centro de convenções da Amazônia - que possui estacionamento privativo." 
      />
      <FaqItem 
        question="Onde almoçar ou fazer um lanche durante intervalos?" 
        answer="O próprio Hangar - Centro de convenções da Amazônia - conta com um restaurante no local (sujeito à disponibilidade de lugares)." 
      />
      {/* Adicione mais FaqItem conforme necessário */}
    </div>
  );
};

export default Faq;
