import React from "react";
import './Home.css';
import dregaderoxo from '../img/ROXO.png';
import rvlatos from '../img/REVIVAL ATOS.png';
import degradelarnja from '../img/LARANJA.png';
import nave from '../img/nave.jpg';
import Carrossel from "../components/Carrossel";
import monumento from "../img/MONUMENTO.png";
import folha1 from "../img/FOLHA 01.png";
import folha2 from "../img/FOLHA 02.png";
import folha3 from "../img/FOLHA 03.png";
import folha4 from "../img/FOLHA 04.png";
import ing1 from "../img/Revival Tiketo Pass T_GenZ.png";
import ing2 from "../img/Revival Tiketo Pass T_Room.png";
import ing3 from "../img/Revival Tiketo Pass T_Caravana.png";
import ing4 from "../img/ingressos3.png";
import Faq from "../components/Faq";
import Navbar from "../components/Navbar";
import info from '../img/informacao.png';
import gp from '../img/grupo.png';
import ticket from '../img/ticket.png';
import useWindowSize from "../components/useWindowSize";
import Carrosselmob from "../components/Carrosselmob";
import roomC from "../img/Revival Pass COSTA_Room.png";
import genzC from "../img/Revival Pass COSTA_GenZ.png";
import tripleC from "../img/Revival Pass COSTA_Triplo.png";
import caraC from "../img/Revival Pass COSTA_Caravana.png";
import patrocinio from '../img/patrocinioB.png'
import patrocinadores1 from '../img/Reserva branco .png'; // Exemplo de logo de patrocinador
import patrocinadores2 from '../img/LOGO BG BLACK.png'; // Exemplo de logo de patrocinador
import patrocinadores3 from '../img/2-BRANCO-GRAN-CAR-MEDIDA-OFICIAL.png'; // Exemplo de logo de patrocinador

function Home(){
    
    const { width } = useWindowSize();
    

    const isMobile = window.innerWidth <= 1050;
    return(
        <>
            
            <div className="container">
                <Navbar/> 
                <section id="home">  
                <video className="videoabertura " autoPlay playsInline loop muted >
                 <source src="/videos/rvl higt light.mp4" type="video/mp4" />
                 Seu navegador não suporta o elemento de vídeo.
                </video>
                </section>
                <img src={dregaderoxo} className="dregader" alt="Descrição da imagem"/>
                <img src={rvlatos} className="rvlatos" alt="Descrição da imagem"/>
                <a href="https://www.tiketo.com.br/evento/1723" className="cta-button">
                    QUERO FAZER PARTE DA RVL24
                </a>
                
    
            </div>
            <img src={degradelarnja} className="degradel" alt=""/>
             <div className="info-section">
                <div className="barra"></div>
                <section id="info">
                <div className="text-container">
                    <div className="title-with-icon">
                        <img src={info} alt="Info Icon" className="iconinfo" />
                        <div class="vr"></div>
                        <h1>O que é a Revival Conference?</h1>
                    </div>  
                    <p>Chegamos na 4ª EDIÇÃO DA REVIVAL CONFERENCE, a conferência cristã, interdenominacional, que busca gerar avivamento para a cidade de Belém. Cremos que nossa nação está num tempo de ativação do Reino dos céus. E nós, como igreja de Cristo, estamos nos posicionando para viver e ativar esse movimento no norte do Brasil.</p>
                    <p>A RVL 24 acontece nos dias 04 e 05 de outubro, no Hangar Centro de Convenções da Amazônia, com preletores nacionais e internacionais. Dentro da conferência teremos sala profética, workshops, diversos stands e muita coisa incrível para você viver uma experiência completa e ter sua vida impactada nesses dias.</p>
                    <p>Em todas as edições recebemos testemunhos de pessoas que foram avivadas, transformadas e curadas durante a Revival Conference. Cremos que prodígios, milagres e maravilhas vão seguir acontecendo sobre nós e sobre Belém e que muitas pessoas ainda irão receber palavras de destinos, para serem usados nas esferas da sociedade, nos 4 cantos do Brasil e em todo o mundo.</p>
                    <div className="infocenter">
                        <a href="https://www.tiketo.com.br/evento/1723" className="info-button">
                        QUERO FAZER PARTE DA RVL24
                        </a>
                    </div>
                </div>
                </section>
                <img src={nave} className="nave" alt=""/>

            </div>
            <div className="linecarrosel">
            <section id="team">
                <div className="title-with-icon2">
                    <img src={gp} alt="Team Icon" className="iconline" />
                    <div class="vr2"></div>
                    <h1 className="linetitulo">Line-Up</h1>
                </div>
                {width > 1050 ? <Carrossel /> : <Carrosselmob />} 
                </section>

            </div>
            <section id="tickets">
            <div className="revpass">   
                <div className="title-with-icon2">
                    <img src={ticket} alt="Tickets Icon" className="iconpass"/>
                    <div class="vr2"></div>
                    <h1 className="titlopass">Revival Pass 2024</h1>
                </div>
                <img src={monumento} className="monu" alt=""/>
                <img src={folha1} className="folha1" alt=""/>
                <img src={folha2} className="folha2" alt=""/>
                <img src={folha3} className="folha3" alt=""/>
                <img src={folha4} className="folha4" alt=""/>
               
            </div>
            </section>

            <div className="grid ">
                <div className="g-col-6">
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                            {!isMobile ? (
                                <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                    <img src={ing2} className="ing2" alt="Revival Room" />
                                </a>
                            ) : (
                                <img src={ing2} className="ing2" alt="Revival Room" />
                            )}
                            </div>
                            <div className="back">
                            <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                <img src={roomC} alt="Background "/>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="g-col-6">
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                            {!isMobile ? (
                                <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                    <img src={ing1} className="ing1" alt="Gez Pass" />
                                </a>
                            ) : (
                                <img src={ing1} className="ing1" alt="Gez pass" />
                            )}
                            </div>
                            <div className="back">
                            <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                <img src={genzC} alt="Background "/>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="g-col-6">
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                            {!isMobile ? (
                                <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                    <img src={ing4} className="ing3" alt="Triple Pass" />
                                </a>
                            ) : (
                                <img src={ing4} className="ing3" alt="Triple Pass" />
                            )}
                            </div>
                            <div className="back">
                            <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                <img src={tripleC} alt="Background "/>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="g-col-6">
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                            {!isMobile ? (
                                <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                    <img src={ing3} className="ing2" alt="Caravana Pass" />
                                </a>
                            ) : (
                                <img src={ing3} className="ing2" alt="Caravan Pass" />
                            )}
                            </div>
                            <div className="back">
                            <a href="https://www.tiketo.com.br/evento/1723" target="_blank" rel="noopener noreferrer">
                                <img src={caraC} alt="Background "/>
                            </a>
                            </div>
                        </div>

                    </div>
                </div>   
            </div>
            <section id="faq">
            <Faq/>
            </section>

            <div className="sponsorship-section">
            <section id="pat">
            <div className="sponsorship-header">
                <img src={patrocinio} alt="Patrocínio Icon" className="sponsorship-icon" />
                <div class="vr2"></div>
                <h1 className="titlopass">PATROCÍNIO</h1>
            </div>
            <div className="sponsorship-logos">
                <img src={patrocinadores1} alt="Logo 1" />
                <img src={patrocinadores2} alt="Logo 2" />
                <img src={patrocinadores3} alt="Logo 3" />
            </div>
            </section>
            </div>
        

        </>
    );
}

export default Home;
