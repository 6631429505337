import React, { useState, useEffect } from "react";
import './Navbar.css';
import house from '../img/home.png';
import houseActive from '../img/homeL.png'; // Versão laranja do ícone
import info from '../img/informacao.png';
import infoActive from '../img/infoL.png'; // Versão laranja do ícone
import gp from '../img/grupo.png';
import gpActive from '../img/groupL.png'; // Versão laranja do ícone
import ticket from '../img/ticket.png';
import ticketActive from '../img/ticketL.png'; // Versão laranja do ícone
import quest from '../img/quest.png';
import questActive from '../img/faqL.png'; // Versão laranja do ícone
import patrocinio from '../img/patrocinioB.png'
import patrocinioActive  from '../img/patrocinioL.png'

export default function Navbar() {
    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
                    setActiveSection(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className="vertical-navbar">
                <a href="#home" className={`nav-item ${activeSection === 'home' ? 'active' : ''}`}>
                    <img src={activeSection === 'home' ? houseActive : house} alt="Home Icon" />
                </a>
                <a href="#info" className={`nav-item ${activeSection === 'info' ? 'active' : ''}`}>
                    <img src={activeSection === 'info' ? infoActive : info} alt="Info Icon" />
                </a>
                <a href="#team" className={`nav-item ${activeSection === 'team' ? 'active' : ''}`}>
                    <img src={activeSection === 'team' ? gpActive : gp} alt="Team Icon" />
                </a>
                <a href="#tickets" className={`nav-item ${activeSection === 'tickets' ? 'active' : ''}`}>
                    <img src={activeSection === 'tickets' ? ticketActive : ticket} alt="Tickets Icon" />
                </a>
                <a href="#faq" className={`nav-item ${activeSection === 'faq' ? 'active' : ''}`}>
                    <img src={activeSection === 'faq' ? questActive : quest} alt="FAQ Icon" />
                </a>
                <a href="#pat" className={`nav-item ${activeSection === 'pat' ? 'active' : ''}`}>
                    <img src={activeSection === 'pat' ? patrocinioActive : patrocinio} alt="Patrocinio Icon" />
                </a>
            </div>
        </>
    );
}
