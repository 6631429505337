import React,{ useState }  from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from "swiper/modules";

import vitor from '../img/VITOR LEDO.jpeg';
import mayra from '../img/MAYRA LEDO.jpeg';
import daniel from '../img/WhatsApp Image 2024-08-17 at 22.31.52 (2).jpeg';
import cantarino from '../img/WhatsApp Image 2024-08-17 at 22.31.52 (3).jpeg';
import chelo from '../img/CHELO BELTRAN.jpeg';
import dunamis from '../img/DUNAMIS MUSIC.jpeg';
import link from '../img/LINK MUSIC.jpg';

import './Carrosselmob.css';

export default function Carrosselmob() {
    const [activeIndex, setActiveIndex] = useState(0);

  const descriptions = [
    "Vitor Ledo é o fundador da Link Church junto com a sua esposa Mayra. Há 6 anos sua família vem caminhando sob uma visão que Deus lhe deu: uma bomba de avivamento estourando no centro de Belém e resvalando para toda a cidade. Desde então, Vitor vem ativando cristãos e se tornando uma voz fiel e corajosa no norte do Brasil linkando gerações e corações que buscam transformação por onde passam.",
    "Mayra Ledo é pastora da Link Church, ao lado de seu marido, Vitor Ledo. Além disso, lidera o ministério de mulheres Glow. Mãe de três filhos, é formada em Direito e trabalha como funcionária concursada do Ministério Público do Estado há 10 anos. Apesar das inúmeras responsabilidades em sua vida, ela continua fiel ao chamado que recebeu desde a infância, servindo a Jesus. Mayra tem se dedicado a formar e inspirar uma geração de mulheres, esposas e mães que são apaixonadas por Jesus.",
    "Daniel Kalta nasceu em um lar multicultural, com pai egípcio e mãe inglesa. Seus pais atuam como missionários há 35 anos e com isso, Kalta sempre foi uma criança missionária. Nos últimos 10 anos serviu a Jocom em Kona e em 2020 se mudou para o Brasil para liderar o projeto Fire & Fragance Brasil e fazer parte da liderança do Dunamis Moviment. É casado com a brasileira Midy e tem dois filhos maravilhosos: Léo e Chloe.",
    "Gabriel Cantarino é pastor da Igreja One, servindo atualmente em Imperatriz no Maranhão. Gabriel tem gastado sua vida pela igreja de Cristo. Nos últimos anos liderou o movimento de evangelismo 'Sobre toda a carne' que impactou a cidade do Rio de Janeiro e São Paulo através de cultos nas ruas e evangelismos urbanos. Cantarino carrega uma voz profética para a nossa geração e nação, na qual provava a igreja a uma vida santidade, jejum e devoção ao senhor. Hoje, servindo no nordeste, Gabriel deseja cooperar com o que o Senhor deseja fazer da região e servir a igreja com o que ele e sua família carregam.",
    "Chello Beltran é missionário da YWAM (Youth With a Mission) há mais de 30 anos na América Latina, engenheiro, pastor, conselheiro, ajudou a fundar diversas bases ao redor do mundo (Uruguai, Chile, USA, Brasil) e faz parte do Conselho Mundial da Escola de Aconselhamento da YWAM, seu ministério é marcado por amor, cura e paternidade.",
    "O coração do Dunamis Music®️ é de servir ao Corpo de Cristo e promover o Evangelho, levantando uma geração de jovens que se posicionam com ousadia e confiança através da adoração. O ministério em si requer um equilíbrio entre ser guiado pelo Espírito Santo e ser um bom mordomo do que Deus nos provê e coloca em nossos caminhos para cuidarmos.",
    "O Link Music é o ministério de louvor e adoração da Link Church. Marcado por uma adoração extravagante e que leva o público não a um entretenimento mas a uma experiência de adoração verdadeira a Deus por meio do louvor."
  ];
  return (
    <>
    <div className="carousel-mobile-container">
    <div className="line1"></div>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
        modules={[Autoplay, Pagination]}
        className="mySwiperMobile"
      >
        <SwiperSlide>
          <div className="mobile-card">
            <img src={vitor} alt="Vitor Ledo" />
            <div className="mobile-card-info">
              <h3>Pr. Vitor Ledo | Link Church</h3>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mobile-card">
            <img src={mayra} alt="Mayra Ledo" />
            <div className="mobile-card-info">
              <h3>Pr. Mayra Ledo | Link Church</h3>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mobile-card">
            <img src={daniel} alt="Daniel Kalta" />
            <div className="mobile-card-info">
              <h3>Daniel Kalta</h3>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mobile-card">
            <img src={cantarino} alt="Gabriel Cantarino" />
            <div className="mobile-card-info">
              <h3>Gabriel Cantarino</h3>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mobile-card">
            <img src={chelo} alt="Chelo Beltran" />
            <div className="mobile-card-info">
              <h3>Chelo Beltran</h3>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mobile-card">
            <img src={dunamis} alt="Dunamis Music" />
            <div className="mobile-card-info">
              <h3>Dunamis Music</h3>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="mobile-card">
            <img src={link} alt="Link Music" />
            <div className="mobile-card-info">
              <h3>Link Music | Link Church</h3>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <div className="description-container">
        <p>{descriptions[activeIndex]}</p>
      </div>
    </>
  );
}
